import React from "react";
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  Image,
} from "react-native-web";
import { Images, Colors, Fonts } from "../../config";
import CImage from "./CImage";

const CCheckBox = (props) => {
  const { checked, type = "radio", isMobileVersion } = props;
  const hasSuffix = props.suffix != undefined;
  const onPress = () => {
    if (props.onPress) {
      props.onPress();
    }
  };
  return (
    <TouchableOpacity
      style={[
        styles.container,
        props.isDisabled ? { opacity: 0.2 } : {},
        props.style,
      ]}
      activeOpacity={props.isDisabled ? 0.2 : 0.2}
      onPress={() => (props.isDisabled ? null : onPress())}>
      <View
        style={{
          marginRight: isMobileVersion ? 8 : 16,
        }}>
        <CImage
          source={
            type === "radio"
              ? checked
                ? Images.iconRadioChecked
                : Images.iconRadioUnchecked
              : checked
              ? Images.iconChecked
              : Images.iconUnChecked
          }
          width={isMobileVersion ? 16 : 24}
          height={isMobileVersion ? 16 : 24}
          layout="fixed"
        />
      </View>
      {props.prefixIcon && <div className="mr-2">{props.prefixIcon}</div>}
      <Text
        style={[
          styles.text,
          isMobileVersion
            ? {
                fontSize: 12,
                lineHeight: 20,
              }
            : {},
        ]}>
        {props.label}
      </Text>
      {hasSuffix && (
        <Text
          style={[
            styles.suffix,
            isMobileVersion
              ? {
                  fontSize: 12,
                  lineHeight: 20,
                }
              : {},
          ]}>
          {props.suffix}
        </Text>
      )}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
  },
  text: {
    color: Colors.white,
    fontFamily: Fonts.primaryRegular,
    fontSize: 16,
    lineHeight: 20,
  },
  suffix: {
    color: Colors.white50,
    fontFamily: Fonts.primaryRegular,
    fontSize: 16,
    lineHeight: 20,
    marginLeft: 4,
  },
});

export default CCheckBox;
