import {
  StyleSheet,
  Text,
  View,
  Image,
  TouchableOpacity,
} from "react-native-web";
import React, { useState } from "react";
import { Colors, Fonts, Images } from "../../../config";
import { useRouter } from "next/router";

const SearchTopbarComponent = (props) => {
  const [opened, setopened] = useState(false);
  const navigation = useRouter();

  return (
    <View style={styles.container}>
      {opened ? (
        <TouchableOpacity
          style={{
            width: 150,
            height: 40,
            backgroundColor: "red",
            position: "absolute",
            borderRadius: 16,
            backgroundColor: Colors.black,
            borderWidth: 1,
            borderColor: Colors.white10,
            bottom: -30,
            right: 16,
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <Image
            source={Images.iconArchieve}
            style={{ width: 16, height: 16, marginRight: 8 }}
          />
          <Text
            style={{
              fontFamily: Fonts.primaryRegular,
              color: Colors.white,
              fontSize: 16,
            }}
          >
            Archived chats
          </Text>
        </TouchableOpacity>
      ) : null}
      <TouchableOpacity onPress={() => navigation.goBack()}>
        <Image source={Images.iconAngleLeft} style={styles.icon} />
      </TouchableOpacity>
      <View style={styles.titleContainer}>
        <Text style={styles.title}>{props.title}</Text>
      </View>

      <View
        style={{
          position: "absolute",
          right: 10,
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <TouchableOpacity
          onPress={() => {
            null;
          }}
        >
          <Image
            source={Images.iconSearch}
            style={{
              width: 18,
              height: 18,
              marginRight: 20,
              resizeMode: "contain",
              justifyContent: "center",
              alignItems: "center",
            }}
          />
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => {
            setopened(!opened);
          }}
        >
          <Image
            source={Images.iconMore}
            style={{ width: 22, height: 22, resizeMode: "contain" }}
          />
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default SearchTopbarComponent;

const styles = StyleSheet.create({
  container: {
    width: "100%",
    backgroundColor: Colors.black,
    height: 48,
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 20,
  },
  title: {
    fontSize: 16,
    fontFamily: Fonts.primaryBold,
    color: Colors.white,
  },
  titleContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    width: 24,
    height: 24,
    resizeMode: "contain",
  },
});
