import React, { Component } from "react";
import {
  StyleSheet,
  Image,
  View,
  TouchableWithoutFeedback,
  Text,
  FlatList,
} from "react-native-web";
import { nFormatter } from "../../../../helpers";
import { Values, Colors, Fonts } from "../../../config";
import { makeArrayUniq } from "../../../helpers/arrayHelpers";
import BottomBorderTabItem from "./BottomBorderTabItem";
import ColoredRoundTabItem from "./ColoredRoundTabItem";
import RoundTabItem from "./RoundTabItem";

export class CTabSelector extends Component {
  constructor(props) {
    super(props);
  }
  onSelectTab = (item, index) => {
    if (this.props.onSelectTab) {
      this.props.onSelectTab(item, index);
    }
    if (this.props.tabItemType == "bottom-line-tab") {
      if (this.listRef) {
        this.listRef.scrollToIndex({ index });
      }
    }
  };

  renderTabItem = (item, index, totalCount) => {
    let { selectedIndex, maxValue } = this.props;
    let titleText = item.title;
    if (item.countData) {
      titleText +=
        " " + makeArrayUniq(item.countData?.map((i) => i.id))?.length || "0";
    } else {
      if (item.count !== null && item.count !== undefined) {
        if (!this.props.withoutMax) {
          if (item.count > (maxValue || 99)) {
            titleText += maxValue ? maxValue + "+" : " 99+";
          } else {
            titleText += " " + (item.count || "0");
          }
        } else {
          if (maxValue && item.count > (maxValue || 99)) {
            titleText += " " + maxValue + "+" || " 99+";
          } else {
            titleText += " " + (nFormatter(item.count) || "0");
          }
        }
      }
    }

    const isPicked = parseInt(index) == parseInt(selectedIndex);
    const isLast = index === totalCount - 1;
    if (this.props.renderTabItem) {
      return this.props.renderTabItem(item, index, isPicked, isLast);
    }

    if (this.props.tabItemType == "bottom-line-tab") {
      return (
        <BottomBorderTabItem
          isPicked={isPicked}
          onSelectTab={this.onSelectTab}
          key={"tab" + index}
          index={index}
          item={item}
          style={[this.props.showInView && { flex: 1 }]}
          isMobileVersion={this.props.isMobileVersion}
        />
      );
    }
    if (this.props.tabItemType == "round-tab") {
      return (
        <RoundTabItem
          isPicked={isPicked}
          onSelectTab={this.onSelectTab}
          key={"tab" + index}
          index={index}
          item={item}
          isMobileVersion={this.props.isMobileVersion}
          showMax={this.props.showMax}
        />
      );
    }
    if (this.props.tabItemType == "colored-round-tab") {
      return (
        <ColoredRoundTabItem
          isPicked={isPicked}
          onSelectTab={this.onSelectTab}
          key={"tab" + index}
          index={index}
          item={item}
          style={{
            marginRight: isLast ? 0 : this.props.isMobileVersion ? 10 : 20,
          }}
          isMobileVersion={this.props.isMobileVersion}
          showMax={this.props.showMax}
          withoutFullWidth={this.props.withoutFullWidth}
        />
      );
    }
    if (!item) return null;

    return (
      <TouchableWithoutFeedback
        key={"tab" + index}
        onPress={() => {
          this.onSelectTab(item, index);
        }}>
        <View
          style={[
            styles.tabItemContainer,
            {
              position: "relative",
            },
          ]}>
          {isPicked && (
            <div
              className="bg-gradient-to-l from-gradient3 via-gradient2 to-gradient1 rounded-full"
              style={{
                position: "absolute",
                left: 0,
                top: 0,
                right: 0,
                bottom: 0,
              }}
            />
          )}
          <Text
            style={[
              isPicked ? styles.selectedTabItemText : styles.tabItemText,
              this.props.isMobileVersion
                ? {
                    fontSize: 12,
                    lineHeight: 14,
                  }
                : {},
            ]}>
            {titleText}
          </Text>
        </View>
      </TouchableWithoutFeedback>
    );
  };
  renderTabs = (items) => {
    if (!items) return null;
    if (
      this.props.showInView ||
      this.props.tabItemType == "round-tab" ||
      this.props.tabItemType == "colored-round-tab"
    ) {
      return this.renderTabsInView(items);
    }
    return (
      <FlatList
        ref={(instance) => {
          this.listRef = instance;
        }}
        data={items}
        horizontal={true}
        showsHorizontalScrollIndicator={false}
        renderItem={({ item, index }) => this.renderTabItem(item, index)}
        keyExtractor={(item, index) => index.toString()}
        extraData={this.props}
      />
    );
  };
  renderTabsInView = (items) => {
    const totalCount = items.length;
    return items.map((item, index) =>
      this.renderTabItem(item, index, totalCount)
    );
  };

  render() {
    if (this.props.tabItemType == "bottom-line-tab") {
      return (
        <View
          style={[
            { alignSelf: "stretch", position: "relative" },
            this.props.containerStyle,
          ]}>
          <View style={[styles.tabContainer, styles.bottomContainer]}>
            {this.renderTabs(this.props.items)}
          </View>

          <View style={styles.bottomLine} />
        </View>
      );
    }
    return (
      <View
        style={[
          styles.tabContainer,
          //{ paddingLeft: 16 },
          this.props.containerStyle,
          this.props.tabItemType == "round-tab" && styles.roundTabContainer,
          this.props.tabItemType == "colored-round-tab" &&
            styles.coloredRoundTabContainer,
          this.props.isMobileVersion
            ? { height: 32, justifyContent: "flex-start" }
            : {},
        ]}>
        {this.renderTabs(this.props.items)}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  tabContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "stretch",
  },

  tabItemContainer: {
    height: 24,
    paddingHorizontal: 10,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: Colors.grayBackground,
    borderRadius: 14,
    marginRight: 6,
    overflow: "hidden",
  },

  tabItemText: {
    fontFamily: Fonts.primaryBold,
    fontSize: 14,
    lineHeight: 14,
    color: Colors.whiteHalf,
  },

  selectedTabItemText: {
    fontFamily: Fonts.primaryBold,
    fontSize: 14,
    lineHeight: 14,
    color: Colors.white,
    zIndex: 99999000000,
  },

  bottomContainer: {
    alignSelf: "stretch",
    zIndex: 2,
  },

  bottomLine: {
    alignSelf: "stretch",
    height: 2,
    backgroundColor: Colors.tabBottomColor,
    position: "absolute",
    bottom: 0,
    left: 0,
    zIndex: 1,
    width: "100%",
  },

  roundTabContainer: {
    height: 50,
    borderWidth: 1,
    alignSelf: "auto",
    borderRadius: 25,
    paddingLeft: 0,
    borderColor: Colors.tabBottomColor,
  },

  coloredRoundTabContainer: {
    paddingLeft: 0,
  },
});

export default CTabSelector;
