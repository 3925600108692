import React, { useEffect, useState } from "react";
import Image from "next/image";
import { Images, Values } from "../../../config";

const CImage = (props) => {
  const [srcError, setSrcError] = useState(null);

  const getSource = () => {
    if (srcError) {
      return srcError;
    }

    if (props.imgUrl) {
      if (props.imgUrl.startsWith("Local:")) {
        const name = props.imgUrl.substring(6);
        return Images[name];
      } else {
        return props.imgUrl;
      }
    } else if (props.source) {
      return props.source;
    }
    return null;
  };

  return (
    <>
      {getSource() !== null && (
        <Image
          src={getSource()}
          alt={props.alt || "photo"}
          className={props.className || ""}
          width={props.width || null}
          height={props.height || null}
          layout={props.layout || "fill"}
          objectFit={props.objectFit || "cover"}
          onError={() => setSrcError("/images/black.png")}
          priority
        />
      )}
    </>
  );
};

export default CImage;
