import React, { PureComponent } from "react";
import Proptypes from "prop-types";
import {
  Text,
  StyleSheet,
  View,
  TouchableOpacity,
  ActivityIndicator,
  Image,
} from "react-native-web";
import Link from "next/link";
import { Colors, Fonts } from "../../config";
import CImage from "./CImage";
export default class IconizedButton extends PureComponent {
  onButtonPress = () => {
    if (this.props.isLoading) {
      return false;
    }
    if (this.props.onPress) this.props.onPress();
  };

  render = () => {
    const borderRadius = { borderRadius: this.props.borderRadius };
    const isLoading = this.props.isLoading;
    const disabled = this.props.disabled;
    const dotted = this.props.dotted;
    return this.props.url ? (
      <Link prefetch={false} href={this.props.url} disabled={disabled}>
        <a className="w-full">
          <View
            style={[
              styles.buttonView,
              disabled ? styles.disabled : null,
              this.props.filled ? styles.filled : null,
              this.props.filled && this.props.filledColor
                ? { backgroundColor: this.props.filledColor }
                : null,

              this.props.bordered ? styles.border : null,
              borderRadius,
              dotted
                ? {
                    borderStyle: "dashed",
                    borderWidth: 1,
                    borderColor: "#FF7699",
                  }
                : null,
              this.props.isMobileVersion
                ? { height: this.props.bordered ? 40 : 38 }
                : {},
              this.props.style,
            ]}>
            {this.props.colored && (
              <div
                className="bg-gradient-to-l from-gradient3 via-gradient2 to-gradient1"
                style={styles.mask}
              />
            )}
            <View style={styles.button}>
              {this.props.icon && (
                <View style={{ marginRight: 6 }}>
                  <CImage
                    width={this.props.iconStyle?.width || 22}
                    height={this.props.iconStyle?.height || 22}
                    layout="fixed"
                    source={this.props.icon}
                    style={styles.icon}
                    resizeMode={"contain"}
                    alt={this.props.buttonTitle?.toLowerCase()}
                  />
                </View>
              )}
              <Text
                style={[
                  this.props.filled ? styles.filledTitle : styles.title,
                  this.props.isMobileVersion ? { fontSize: 12 } : {},
                  this.props.filled && this.props.filledTextColor
                    ? { color: this.props.filledTextColor }
                    : null,
                  { fontSize: this.props.fontSize },
                ]}>
                {this.props.isHeading ? (
                  <h3>
                    {isLoading && this.props.loadingTitle
                      ? this.props.loadingTitle
                      : this.props.buttonTitle}
                  </h3>
                ) : isLoading && this.props.loadingTitle ? (
                  this.props.loadingTitle
                ) : (
                  this.props.buttonTitle
                )}
              </Text>
              {isLoading && (
                <View style={{ left: 8 }}>
                  <ActivityIndicator
                    color={
                      this.props.filled
                        ? styles.filledIndicator
                        : "rgba(255,255,255,0.8)"
                    }
                  />
                </View>
              )}
            </View>
          </View>
        </a>
      </Link>
    ) : (
      <TouchableOpacity
        style={[
          styles.buttonView,
          disabled ? styles.disabled : null,
          this.props.filled ? styles.filled : null,
          this.props.filled && this.props.filledColor
            ? { backgroundColor: this.props.filledColor }
            : null,

          this.props.bordered ? styles.border : null,
          borderRadius,
          dotted
            ? { borderStyle: "dashed", borderWidth: 1, borderColor: "#FF7699" }
            : null,
          this.props.isMobileVersion
            ? { height: this.props.bordered ? 40 : 38 }
            : {},
          this.props.style,
        ]}
        onPress={this.onButtonPress}
        disabled={disabled}>
        {this.props.colored && (
          <div
            className="bg-gradient-to-l from-gradient3 via-gradient2 to-gradient1"
            style={styles.mask}
          />
        )}
        <View style={styles.button}>
          {this.props.icon && (
            <View style={{ marginRight: 6 }}>
              <CImage
                width={this.props.iconStyle?.width || 22}
                height={this.props.iconStyle?.height || 22}
                layout="fixed"
                source={this.props.icon}
                style={styles.icon}
                resizeMode={"contain"}
              />
            </View>
          )}
          <Text
            style={[
              this.props.filled ? styles.filledTitle : styles.title,
              this.props.isMobileVersion ? { fontSize: 12 } : {},
              this.props.filled && this.props.filledTextColor
                ? { color: this.props.filledTextColor }
                : null,
              { fontSize: this.props.fontSize },
            ]}>
            {this.props.isHeading ? (
              <h3>
                {isLoading && this.props.loadingTitle
                  ? this.props.loadingTitle
                  : this.props.buttonTitle}
              </h3>
            ) : isLoading && this.props.loadingTitle ? (
              this.props.loadingTitle
            ) : (
              this.props.buttonTitle
            )}
          </Text>
          {isLoading && (
            <View style={{ left: 8 }}>
              <ActivityIndicator
                color={
                  this.props.filled
                    ? styles.filledIndicator
                    : "rgba(255,255,255,0.8)"
                }
              />
            </View>
          )}
        </View>
      </TouchableOpacity>
    );
  };
}

IconizedButton.proptypes = {
  buttonTitle: Proptypes.string.isRequired,
  onPress: Proptypes.func.isRequired,
  filled: Proptypes.bool.isRequired,
  borderRadius: Proptypes.number,
};

IconizedButton.defaultProps = {
  borderRadius: 60,
};

const styles = StyleSheet.create({
  mask: {
    right: 0,
    bottom: 0,
    top: 0,
    left: 0,
    position: "absolute",
  },
  button: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  filled: {
    backgroundColor: "white",
  },
  title: {
    fontSize: 16,
    color: "white",
    fontFamily: Fonts.primaryBold,
  },
  filledTitle: {
    fontSize: 16,
    color: Colors.black,
    fontFamily: Fonts.primaryBold,
  },
  filledIndicator: {
    color: "#FF7699C8",
  },
  disabled: {
    opacity: 0.7,
  },
  buttonView: {
    height: 50,
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
  },
  icon: {
    width: 20,
    height: 20,
    marginRight: 9,
  },
  border: {
    borderWidth: 1,
    borderColor: Colors.spliterLine,
  },
});
