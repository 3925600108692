import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  Image,
} from "react-native-web";
import { Images, Colors, Fonts } from "../../config";
import { useTranslation } from "react-i18next";

const ReadMoreText = (props) => {
  const { t } = useTranslation();
  const { isMobileVersion } = props;
  const MAX_LENGTH = 680;
  const MIN_VISIBLE_LINES = 6.5; // minimum visible lines of text
  const LINE_HEIGHT = isMobileVersion ? 20 : 24; // line height in pixels
  const contentRef = useRef(null);
  const [numVisibleLines, setNumVisibleLines] = useState(MIN_VISIBLE_LINES);
  const [minHeight, setMinHeight] = useState(MIN_VISIBLE_LINES * LINE_HEIGHT);

  const [isReadMore, setIsReadMore] = useState(true);

  const isVisibleReadMore =
    props.text?.replace(/(<([^>]+)>)/gi, "").length > MAX_LENGTH;

  const handleResize = useCallback(
    (event) => {
      if (contentRef.current) {
        if (isReadMore) {
          const containerHeight = event?.target?.offsetHeight;
          const minContentHeight = contentRef?.current?.offsetHeight;
          const numVisibleLines = Math.floor(containerHeight / LINE_HEIGHT);
          setNumVisibleLines(numVisibleLines);
          setMinHeight(minContentHeight);
        } else {
          setNumVisibleLines(MIN_VISIBLE_LINES);
          setMinHeight(MIN_VISIBLE_LINES * LINE_HEIGHT);
        }
      }
    },
    [contentRef, isReadMore]
  );

  useEffect(() => {
    const observer = new ResizeObserver(handleResize);
    observer?.observe(contentRef.current);
  }, [handleResize]);

  return (
    <View style={[styles.container, props.style]}>
      <div
        className="read-more-text-container"
        ref={contentRef}
        style={{
          color: Colors.white,
          fontFamily: Fonts.primaryRegular,
          fontSize: isMobileVersion ? 12 : 16,
          lineHeight: `${LINE_HEIGHT}px`,
          height:
            isReadMore && isVisibleReadMore
              ? `${
                  numVisibleLines *
                  (isMobileVersion ? LINE_HEIGHT + 4 : LINE_HEIGHT)
                }px`
              : "auto",
          overflow: "hidden",
        }}
        dangerouslySetInnerHTML={{
          __html: props.text?.replace(
            /<p>&nbsp;<\/p>|<p> <\/p>|<p> <\/p>|<p><\/p>/g,
            ""
          ),
        }}></div>
      {isVisibleReadMore && (
        <TouchableOpacity
          style={styles.buttonContainer}
          onPress={() => {
            setIsReadMore(!isReadMore);
          }}>
          <Text
            style={[
              styles.button,
              isMobileVersion ? { fontSize: 12 } : {},
              props.buttonStyle,
            ]}>
            {isReadMore ? t("key_readmore") : t("key_readless")}
          </Text>
        </TouchableOpacity>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: { paddingHorizontal: 16, textAlign: "left" },
  buttonContainer: {
    borderWidth: 1,
    borderRadius: 50,
    borderColor: Colors.spliterLine,
    paddingTop: 6,
    paddingBottom: 8,
    paddingHorizontal: 24,
    marginTop: 15,
    alignSelf: "center",
    width: 150,
  },
  button: {
    color: Colors.white,
    fontFamily: Fonts.primaryBold,
    fontSize: 14,
    lineHeight: 16,
    textAlign: "center",
  },
});

export default ReadMoreText;
