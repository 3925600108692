import React from "react";
import {
  StyleSheet,
  View,
  Text,
  TouchableWithoutFeedback,
  Image,
  TouchableOpacity,
} from "react-native-web";
import { Images, Colors, Fonts } from "../../../config";
import { useRouter } from "next/router";

const ColoredRoundTabItem = (props) => {
  const navigation = useRouter();
  const { isMobileVersion } = props;
  let titleText = props.item.title;
  if (props.item.count) {
    if (props.item.count > 99) {
      titleText += " 99+";
    } else {
      titleText += " " + props.item.count;
    }
  }
  const disabled = props.item.disabled;
  const onSelectTab = (item, index) => {
    if (props.onSelectTab) {
      props.onSelectTab(item, index);
    }
  };

  return (
    <TouchableWithoutFeedback
      disabled={disabled}
      onPress={() => {
        onSelectTab(props.item, props.index);
      }}>
      <View
        style={[
          styles.tabItemContainer,
          isMobileVersion
            ? {
                height: 24,
                paddingHorizontal: 10,
                paddingVertical: 5,
              }
            : {},
          props.withoutFullWidth
            ? {}
            : {
                flex: 1,
              },
          props.isPicked && styles.selectedTabContainer,
          props.style,
        ]}>
        {props.isPicked ? (
          <div
            className="bg-gradient-to-l from-gradient3 via-gradient2 to-gradient1 cursor-pointer rounded-full"
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              right: 0,
              bottom: 0,
            }}
          />
        ) : (
          <div
            className="cursor-pointer"
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              right: 0,
              bottom: 0,
            }}
          />
        )}
        <Text
          style={[
            props.isPicked
              ? styles.selectedTabItemText
              : disabled
              ? styles.tabItemText
              : styles.selectedTabItemText,
            isMobileVersion ? { fontSize: 12, lineHeight: 14 } : {},
            props.withoutFullWidth ? { fontSize: 10 } : {},
            { cursor: "pointer" },
          ]}>
          {titleText}
        </Text>
      </View>
    </TouchableWithoutFeedback>
  );
};

const styles = StyleSheet.create({
  tabItemContainer: {
    height: 50,
    //width:524,
    // paddingHorizontal: 10,
    // paddingVertical: 5,
    backgroundColor: Colors.grayBackground,
    borderRadius: 60,
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
  },

  tabItemText: {
    fontFamily: Fonts.primaryBold,
    fontSize: 16,
    lineHeight: 14,
    color: Colors.whiteHalf,
  },
  selectedTabItemText: {
    zIndex: 99999,
    fontFamily: Fonts.primaryBold,
    fontSize: 16,
    lineHeight: 14,
    color: Colors.white,
  },
});

export default ColoredRoundTabItem;
