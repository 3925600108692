import React from "react";
import PropTypes from "prop-types";
import {
  View,
  Platform,
  StyleSheet,
  TextInput,
  Text,
  TouchableOpacity,
} from "react-native-web";
import Image from "next/image";

import { Fonts, Colors, Images } from "../../config";

class IconizedTextInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      eyeOpened: false,
    };
  }
  focus = () => {
    this.textInput.focus();
  };
  render() {
    const { props } = this;
    let isSecureTextEntry = props.secureTextEntry;
    if (props.showDot) {
      isSecureTextEntry = props.secureTextEntry;
    } else if (props.showEye) {
      isSecureTextEntry = !this.state.eyeOpened;
    }
    return (
      <View style={[styles.container, { ...props.containerStyle }]}>
        {props.icon && <Image source={props.icon} style={styles.icon} />}
        <View
          style={{
            alignSelf: "stretch",
            flexDirection: "column",
            paddingLeft: 22,
            flex: 1,
          }}>
          {props.description &&
            (props.error ? (
              <Text
                style={[
                  styles.descritpionError,
                  this.props.isMobileVersion ? { fontSize: 12 } : {},
                ]}>
                {props.description}
              </Text>
            ) : (
              <Text
                style={[
                  styles.description,
                  this.props.isMobileVersion ? { fontSize: 12 } : {},
                ]}>
                {props.description}
              </Text>
            ))}
          <View
            style={{
              alignSelf: "stretch",
              flexDirection: "row",
              alignItems: "center",
              flex: 1,
            }}>
            {props.prefixView}
            {props.isPicker ? (
              <View
                style={{
                  flex: 1,
                }}>
                <TouchableOpacity
                  style={[styles.pickerDefault]}
                  onPress={this.props.onOpenPicker}>
                  {(!this.props.value || this.props.value.length == 0) && (
                    <Text
                      ellipsizeMode={"tail"}
                      numberOfLines={1}
                      style={[
                        styles.textInput,
                        {
                          color:
                            props.placeholderTextColor || Colors.spliterLine,
                          top: 14,
                          position: "absolute",
                        },
                      ]}>
                      {this.props.placeholder}
                    </Text>
                  )}
                  <Text
                    ellipsizeMode={"tail"}
                    numberOfLines={1}
                    style={[
                      styles.textInput,
                      {
                        color: Colors.white,
                        fontFamily: Fonts.primaryRegular,
                        top: 14,
                        position: "absolute",
                        marginRight: 16,
                      },
                      this.props.textInputStyle,
                    ]}>
                    {this.props.value}
                  </Text>
                  {this.props.dropdownIcon && (
                    <Image
                      style={{
                        tintColor: Colors.white,
                        height: 9,
                        width: 9,
                        position: "absolute",
                        right: 12,
                        top: 10,
                      }}
                      resizeMode="contain"
                      source={this.props.dropdownIcon}></Image>
                  )}
                </TouchableOpacity>
              </View>
            ) : props.editDisabled ? (
              <View style={{ flex: 1 }}>
                <View style={[styles.pickerDefault]}>
                  {(!this.props.value || this.props.value.length == 0) && (
                    <Text
                      ellipsizeMode={"tail"}
                      numberOfLines={1}
                      style={[
                        styles.textInput,
                        {
                          color:
                            props.placeholderTextColor || Colors.spliterLine,
                          top: 8,
                          position: "absolute",
                        },
                        this.props.isMobileVersion ? { fontSize: 12 } : {},
                      ]}>
                      {this.props.placeholder}
                    </Text>
                  )}
                  <Text
                    ellipsizeMode={"tail"}
                    numberOfLines={1}
                    style={[
                      styles.textInput,
                      {
                        color: Colors.black,
                        top: 8,
                        position: "absolute",
                        marginRight: 16,
                      },
                      this.props.isMobileVersion ? { fontSize: 12 } : {},
                      this.props.textInputStyle,
                    ]}>
                    {this.props.value}
                  </Text>
                </View>
              </View>
            ) : (
              <TextInput
                ref={(component) => {
                  this.textInput = component;
                }}
                placeholderTextColor={
                  props.placeholderTextColor || Colors.placeholder
                }
                underlineColorAndroid={"transparent"}
                autoCapitalize="none"
                {...props}
                secureTextEntry={isSecureTextEntry}
                style={[
                  styles.default,
                  this.props.isMobileVersion ? { fontSize: 12 } : {},
                  { flex: 1 },
                  this.props.inputStyle,
                ]}
              />
            )}
          </View>

          {!props.isPicker && (
            <View
              style={{
                height: 1,
                backgroundColor: Colors.spliter,
                alignSelf: "stretch",
              }}
            />
          )}

          {this.props.error && (
            <View
              style={{
                flexDirection: "row",
                justifyContent: "flex-start",
                alignSelf: "stretch",
                alignItems: "center",
                marginTop: 0,
              }}>
              <Text
                style={[
                  styles.errorText,
                  this.props.isMobileVersion ? { fontSize: 10 } : {},
                ]}>
                {this.props.error}
              </Text>
            </View>
          )}
          {!this.props.error && this.props.underlineDescription && (
            <View
              style={{
                flexDirection: "row",
                justifyContent: "flex-end",
                alignSelf: "stretch",
                alignItems: "center",
                marginTop: 0,
              }}>
              <Text style={[styles.description, { color: Colors.spliterLine }]}>
                {this.props.underlineDescription}
              </Text>
            </View>
          )}
        </View>
        {!props.showDot && props.showEye && (
          <TouchableOpacity
            onPress={() => {
              this.setState({ eyeOpened: !this.state.eyeOpened });
            }}
            style={styles.iconContainer}>
            <Image
              src={
                this.state.eyeOpened ? Images.iconEyeOpen : Images.iconEyeClosed
              }
              width={14}
              height={14}
              resizeMode="contain"
            />
          </TouchableOpacity>
        )}
        {props.showDot && <View style={styles.dot} />}
        {props.showClose && (
          <TouchableOpacity
            onPress={props.onPressClose}
            style={styles.iconContainer}>
            <Image
              src={Images.iconClose}
              width={14}
              height={14}
              resizeMode="contain"
            />
          </TouchableOpacity>
        )}
      </View>
    );
  }
}

IconizedTextInput.proptypes = {
  description: PropTypes.string,
  icon: PropTypes.object,
  phoneNumberPrefix: PropTypes.string,
  containerStyle: PropTypes.object,
  dropdownIcon: PropTypes.object,
  isPicker: PropTypes.bool,
  textInputStyle: PropTypes.object,
  onOpenPicker: PropTypes.func,
  error: PropTypes.string,
  editDisabled: PropTypes.bool,
};
const HEIGHT = 50;

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    borderWidth: 1,
    borderRadius: HEIGHT / 2,
    height: HEIGHT,
    borderColor: Colors.spliterLine,
  },
  default: {
    color: Colors.white,
    fontFamily: Fonts.primaryRegular,
    fontSize: 16,
    outlineStyle: "none",

    ...Platform.select({
      android: {
        paddingLeft: 0,
        lineHeight: 20,
        paddingVertical: 0,
      },
    }),
  },
  description: {
    fontSize: 16,
    color: Colors.spliterLine,
  },

  descritpionError: {
    fontSize: 16,
    color: Colors.red,
  },
  errorText: {
    fontSize: 16,
    color: Colors.red,
  },
  textInput: {
    fontSize: 16,
  },
  pickerDefault: {
    height: HEIGHT,
    ...Platform.select({
      android: {
        paddingLeft: 5,
        opacity: 0.9,
      },
    }),
  },
  bordered: {
    borderWidth: 1,
    borderColor: Colors.spliterLine,
    borderRadius: 20,
    paddingHorizontal: 20,
  },
  icon: {
    width: 25,
    height: 25,
    marginStart: 0,
  },
  dark: {
    color: Colors.spliterLine,
  },
  primary: {
    borderRadius: HEIGHT / 2,
    backgroundColor: "transparent",
  },
  iconContainer: {
    width: 36,
    height: 36,
    justifyContent: "center",
    alignItems: "center",
    marginRight: 8,
  },
  eyeIcon: {
    width: 14,
    height: 14,
    resizeMode: "contain",
  },
  dot: {
    width: 6,
    height: 6,
    borderRadius: 3,
    marginRight: 16,
    backgroundColor: "white",
  },
});

export default IconizedTextInput;
