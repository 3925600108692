import React from "react";
import { StyleSheet, View, Text, TouchableOpacity } from "react-native-web";
import { useRouter } from "next/router";
import Image from "next/image";

import { Images, Colors } from "../../config";

const BackButton = (props) => {
  const navigation = useRouter();
  const onBackPressed = () => {
    if (props.onBackPressed) {
      props.onBackPressed();
    } else {
      navigation.back();
    }
  };
  return (
    <TouchableOpacity
      onPress={onBackPressed}
      style={
        props.withoutBG
          ? props.isNotAbsolute
            ? styles.buttonContainerWithoutBG
            : styles.absoluteButtonContainerWithoutBG
          : props.isNotAbsolute
          ? styles.buttonContainer
          : styles.absoluteButtonContainer
      }>
      <Image
        src={Images.iconAngleLeft}
        width={props.withoutBG ? 24 : 20}
        height={props.withoutBG ? 24 : 20}
        alt="back"
      />
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  absoluteButtonContainer: {
    backgroundColor: "rgba(0, 0, 0, 0.2)",
    borderRadius: 60,
    width: 30,
    height: 30,
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    top: 16,
    left: 16,
  },
  buttonContainer: {
    margin: 16,
    backgroundColor: "rgba(0, 0, 0, 0.2)",
    borderRadius: 60,
    width: 30,
    height: 30,
    alignItems: "center",
    justifyContent: "center",
  },
  absoluteButtonContainerWithoutBG: {
    position: "absolute",
    left: 0,
    top: 2,
    padding: 16,
  },
  buttonContainerWithoutBG: {
    padding: 16,
  },
});

export default BackButton;
