import React, { useEffect, useState, useCallback } from "react";
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  Image,
} from "react-native-web";
import { Images, Colors, Fonts } from "../../config";
import { useTranslation } from "react-i18next";

const DotSplitedText = (props) => {
  const { t } = useTranslation();
  const { texts, dotStyle, textStyle, isSecondOpacity, isMobileVersion } =
    props;

  const renderText = (text, index, isLastText) => {
    return [
      <Text
        key={index + "text"}
        ellipsizeMode={"tail"}
        numberOfLines={1}
        style={[
          styles.text,
          isMobileVersion
            ? {
                fontSize: 10,
                lineHeight: 18,
              }
            : {},
          textStyle,
          isSecondOpacity && index === 1 ? { opacity: 0.8 } : {},
        ]}>
        {text}
      </Text>,
      !isLastText && (
        <View key={index + "dot"} style={[styles.dot, dotStyle]} />
      ),
    ];
  };
  const renderTexts = (texts) => {
    return texts.map((text, index) => {
      const isLastText = index + 1 == texts.length;
      return renderText(text, index, isLastText);
    });
  };
  return (
    <View style={[styles.container, props.style]}>{renderTexts(texts)}</View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "no-wrap",
  },
  dot: {
    height: 2,
    width: 2,
    borderRadius: 1,
    marginHorizontal: 6,
    backgroundColor: Colors.lightGray,
  },
  text: {
    fontSize: 16,
    lineHeight: 18,
    fontFamily: Fonts.primaryMedium,
    color: Colors.lightGray,
  },
});

export default DotSplitedText;
